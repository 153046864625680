.error-boundary {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: #b7b7b7;

  .message {
    flex: 1;
  }
}
