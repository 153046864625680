// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

body,
html,
#root {
  background-color: #eaeff1;
  width: 100%;
  height: 100vh;
}
span {
  display: inline-flex;
  align-items: center;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 1px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  background-color: #666;
}
::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  height: 10px;
  background-color: #afafaf;
  border-radius: 3px;
}
::-webkit-resizer {
  background-color: #666;
}
* > a {
  color: #117eb4;
  text-decoration: unset;
  &:hover {
    color: #056997;
  }
}
.react-json-view {
  overflow: auto;
}
